<template>
  <header class="grid grid-cols-1 gap-4 sm:grid-cols-2 border-b">
    <div class="relative rounded-lg bg-white px-6 py-5 flex items-center space-x-1">
      <h1 class="text-sm font-medium text-gray-500 hover:text-gray-700">
        {{ title }}
      </h1>
    </div>
    <div class="relative rounded-lg bg-white px-6 py-5 flex items-center space-x-1 justify-end">
      <MainButton
          v-if="this.$route.meta.headerBackToActions"
          @clickAction="$route.meta.backToPath ? $router.push($route.meta.backToPath) : $router.go(-1)"
          :name="$t('global.buttons.back')"
          :bgStyle="'light'"
      />
    </div>
  </header>
</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';

export default {
  name: 'Header',
  components: { MainButton },
  props: {
    title: {
      type: String,
      required: true
    },
    backToAction: {
      type: Boolean,
     'default': false
    }
  }
}
</script>

<style scoped>

</style>
