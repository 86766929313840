<template>
  <div v-if="!loading">
    <Header :title="form.title" :backToAction="false" />
    <div class="mx-auto p-6 xl:max-w-full xl:grid xl:grid-cols-3 border-b">
      <div class="xl:col-span-2 mb-8 xl:pr-8 xl:border-r xl:border-gray-200">
        <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <div class="fieldTitle">
              <MainFieldTitle :label="$t('task.fields.title')" :name="form.title" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="fieldTitle">
              <MainFieldDescription :label="$t('customer.fields.description')" :name="form.description"/>
            </div>
          </div>
          <div class="sm:col-span-2">
            <CheckListsSection
                :checkLists="form.checklists"
                :isPublicPage="true"
            />
          </div>
          <div class="sm:col-span-2 border-t">
            <CommentBox
                :ownerId="form.id"
                :comments="form.comments"
                :prefix="prefix"
                :isPublicPage="true"
            />
          </div>
        </div>
      </div>
      <aside class="xl:pl-8">
        <RightAside
            :editable="false"
            :prefix="prefix"
            :form="form"
            :isPublicPage="true"
        />
      </aside>
    </div>
  </div>
</template>

<script>
import MainFieldTitle from '@/components/Main/Form/MainFieldTitle/MainFieldTitle';
import RightAside from '@/components/Task/RightAside/RightAside';
import { mapActions, mapGetters } from 'vuex';
import CommentBox from '@/components/Main/CommentBox/CommentBox';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import Header from '../../../components/PublicPages/Header';
import CheckListsSection from '@/components/CheckList/CheckListsSection/CheckLists/CheckLists';
import MainFieldDescription from '@/components/Main/Form/MainFieldDescription/MainFieldDescription';

export default {
  name: 'PublicTask',
  components: {
    MainFieldDescription,
    CheckListsSection,
    Header,
    MainButton,
    CommentBox,
    RightAside,
    MainFieldTitle
  },
  data() {
    return {
      loading: true,
      editable: false,
      pageUpdateAction: true,
      prefix: 'task',
      form: {
        id: '',
        title: '',
        description: '',
        listing_id: '',
        customer_id: '',
        customer_apartment_id: '',
        member_id: '',
        assigneeId: '',
        checklists: [],
        due: '',
        files: [],
        members: [],
        membersIds: [],
        comments: [],
        listings: [],
        customerName: '',
        customerApartmentName: ''
      },
      attachedFiles: [],
      formErrors: {}
    }
  },
  computed: {
    ...mapGetters(['getPublicTask'])
  },
  watch: {
    getPublicTask(data) {
      this.form = { ...data };
      this.loading = false;
    }
  },
  mounted() {
    this.handleGetPublicTask({
      uuid: this.$route.params.uuid,
      taskUuid: this.$route.params.taskUuid
    });
  },
  methods: {
    ...mapActions({
      handleGetPublicTask: 'fetchPublicTask'
    })
  }
}
</script>

<style scoped>

</style>
