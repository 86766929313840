<template>
  <div>
    <dt class="text-sm font-medium text-gray-500">
      {{ label }}
    </dt>
    <div class="ql-snow">
      <dd
          v-html="name"
          class="mt-1 text-sm text-gray-900 ql-editor"
      />
    </div>

    <vue-editor v-show="false" />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'MainFieldDescription',
  components: {
    VueEditor
  },
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      required: true
    }
  }
}
</script>

<style scoped>
.ql-editor {
  min-height: inherit;
  font-size: inherit;
  line-height: inherit;
  height: inherit;
  outline: inherit;
  overflow-y: inherit;
  padding: inherit;
  tab-size: inherit;
  -moz-tab-size: inherit;
  text-align: inherit;
  white-space: inherit;
  word-wrap: inherit;
}
</style>
